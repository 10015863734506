export const GA_TRACKING_ID = 'G-PEPTPQR474';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url: string) => {
  // @ts-ignore
  if (!window.gtag) {
    return;
  }

  // @ts-ignore
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (props: { action: any; category: string; label: string; value: string }) => {
  const { action, category, label, value } = props;

  // @ts-ignore
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};
