/** @jsxImportSource @emotion/react */

import React from 'react';
import { CSSObject } from '@emotion/serialize';

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  css?: CSSObject;
}

export default function Img(props: IProps) {
  const { css } = props;

  const styles: CSSObject = {
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    ...css,
  };

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img css={styles} {...props} />;
}
