/** @jsxImportSource @emotion/react */

import React from 'react';
import { Bars } from '@agney/react-loading';
import { useTheme } from '@emotion/react';
import { CSSInterpolation } from '@emotion/serialize';

interface IStyles {
  inline: {
    wrapper: CSSInterpolation;
    spinner: CSSInterpolation;
    message: CSSInterpolation;
  };
  fullscreen: {
    wrapper: CSSInterpolation;
    spinner: CSSInterpolation;
    message: CSSInterpolation;
  };
  container: {
    wrapper: CSSInterpolation;
    spinner: CSSInterpolation;
    message: CSSInterpolation;
  };
}

/**
 * Loading spinner
 * @param props
 * @constructor
 */
const Loading = (props: { message?: string; type?: 'inline' | 'fullscreen' | 'container' }) => {
  const { message, type = 'inline' } = props;
  const theme = useTheme();

  const styles: IStyles = {
    inline: {
      wrapper: {
        display: 'inline-flex',
      },
      spinner: { width: 16, color: theme.colors.primary },
      message: { fontSize: '0.8rem', marginLeft: '0.5rem' },
    },
    fullscreen: {
      wrapper: {
        display: 'flex',
        width: '100vw',
        height: '100vh',
        flexDirection: 'column',
      },
      spinner: { width: 30, color: theme.colors.primary },
      message: { fontSize: '0.8rem', marginTop: '0.5rem' },
    },
    container: {
      wrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        background: 'rgba(255,255,255,0.8)',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 9999,
      },
      spinner: { width: 30, color: theme.colors.primary },
      message: { fontSize: '0.8rem', marginTop: '0.5rem' },
    },
  };

  return (
    <div className="justify-center items-center" css={styles[type].wrapper}>
      <Bars css={styles[type].spinner} />
      {message && <span css={styles[type].message}>{message}</span>}
    </div>
  );
};

export default Loading;
