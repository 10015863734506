/** @jsxImportSource @emotion/react */

import React, { HTMLAttributes } from 'react';
import ReactModal, { Styles } from 'react-modal';
import { VscClose } from 'react-icons/all';
import { useTheme } from '@emotion/react';
import { H3 } from './Headings';

export interface IModalProps extends HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  onClose: () => void;
  title?: string;
  size?: 'sm' | 'md' | 'lg';
}

const Modal = (props: IModalProps) => {
  const { children, visible, onClose, title, size } = props;
  let maxWidth: number;
  const transitionDuration = 250;
  const theme = useTheme();

  switch (size) {
    case 'lg':
      maxWidth = 1024;
      break;
    case 'md':
      maxWidth = 768;
      break;
    case 'sm':
      maxWidth = 600;
      break;
    default:
      maxWidth = 400;
  }

  const styles: Styles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: `opacity ${transitionDuration}ms ease-in-out`,
    },
    content: {
      transition: `all ${transitionDuration}ms ease-in-out`,
      padding: 0,
      position: 'relative',
      minHeight: '8rem',
      boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      border: 'none',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      maxWidth,
    },
  };

  return (
    <ReactModal closeTimeoutMS={transitionDuration} isOpen={visible} onRequestClose={() => onClose()} style={styles}>
      <header className="flex justify-between p1" css={{ borderBottom: `1px solid ${theme.colors.inputBorder}` }}>
        <H3>{title}</H3>
        <button type="button" onClick={() => onClose()} css={{ border: 'none', background: 'none', cursor: 'pointer' }}>
          <VscClose size={25} />
        </button>
      </header>
      {children}
    </ReactModal>
  );
};

export default Modal;
