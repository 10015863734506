/** @jsxImportSource @emotion/react */

import React, { FormHTMLAttributes } from 'react';
import { FormProvider } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types';

interface IProps extends FormHTMLAttributes<HTMLFormElement> {
  onSubmit?: (data: any) => void;
  form: UseFormMethods<any>;
}

/**
 * Form wrapper component
 * @param props
 * @constructor
 */
const Form = (props: IProps) => {
  const { onSubmit, children, form, ...otherProps } = props;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit || (() => {}))} {...otherProps}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
