import facepaint from 'facepaint';
import { Theme } from '@emotion/react';

export const API_URL = `${process.env.REACT_APP_API_HOST}`;

export const defaultMapCoords = {
  lat: -0.1999023,
  lng: -78.4746729,
};

// Some configurable theme colors
export const theme: Theme = {
  colors: {
    primary: '#102360',
    accent: '#D50057',
    active: '#D50057',
    inputBorder: '#e6e6e6',
    secondary: '#D1DD00',
  },
};

export const gutter = 16;

// Set the project's responsive breakpoints
export const breakpoints = [350, 640, 768, 1024, 1280];

export const mq = facepaint(
  breakpoints.map((bp, index) => {
    if (index <= breakpoints.length - 1) {
      return `@media (min-width: ${breakpoints[index + 1]}px)`;
    }

    return '';
  })
);

export const shadows = {
  large: '0 0 20px #000',
};
