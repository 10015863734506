/** @jsxImportSource @emotion/react */

import React from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import { useTransition, animated } from 'react-spring';
import { FiX } from 'react-icons/all';
import { Composition } from '@atomic-layout/emotion';
import { gutter, shadows } from '../constants';
import { H1 } from './Headings';

const maxWidths = {
  large: 1200,
  medium: 800,
  small: 500,
  tiny: 300,
};

interface IProps {
  children: React.ReactNode;
  isOpen: boolean;
  hasPadding?: boolean;
  title: string;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  onCloseComplete?: () => void;
  onCloseClick?: () => void;
  actions?: React.ReactNode[];
}

/**
 * Fixed side panel for item details
 * @param props
 * @constructor
 */
const SidePanel = (props: IProps) => {
  const { children, title, size, isOpen, onCloseComplete, onCloseClick, actions, hasPadding = true } = props;
  const transitions = useTransition(isOpen, null, {
    from: {
      boxShadow: shadows.large,
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      maxWidth: maxWidths[size || 'small'],
      width: '100%',
      transform: 'translate3d(100%,0,0)',
      backgroundColor: 'white',
      zIndex: 1,
    },
    enter: { transform: 'translate3d(0%,0,0)' },
    leave: { transform: 'translate3d(105%,0,0)' },
    onDestroyed: isDestroyed => (isDestroyed && onCloseComplete ? onCloseComplete() : null),
  });

  const panelStyles: CSSInterpolation = {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <>
      {transitions.map(
        result =>
          result.item && (
            <animated.div key={result.key} style={result.props}>
              <div css={panelStyles}>
                <header
                  className="flex justify-between items-center p1"
                  css={{
                    borderBottom: '1px solid #d0d0d0',
                  }}
                >
                  <H1>{title}</H1>
                  <button
                    type="button"
                    onClick={onCloseClick}
                    css={{
                      background: 'none',
                      padding: 0,
                      display: 'flex',
                      border: 'none',
                      cursor: 'pointer',
                      color: '#696969',
                    }}
                  >
                    <FiX size={30} />
                  </button>
                </header>
                <div className={hasPadding ? 'p1' : ''} css={{ flexGrow: 1, overflow: 'auto' }}>
                  {children}
                </div>
                {actions && (
                  <div className="p1">
                    <Composition gap={gutter} autoFlow="column">
                      {React.Children.toArray(actions)}
                    </Composition>
                  </div>
                )}
              </div>
            </animated.div>
          )
      )}
    </>
  );
};

export default SidePanel;
