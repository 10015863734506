import React from 'react';
import { Composition } from '@atomic-layout/emotion';
import Modal, { IModalProps } from './Modal';
import { gutter } from '../constants';

interface IProps extends IModalProps {
  image?: string;
  okLabel?: string;
  cancelLabel?: string;
}

const ViewImageModal = (props: IProps) => {
  const { image, ...otherProps } = props;

  return (
    <Modal {...otherProps} title="Visor de imagenes">
      <Composition gap={gutter * 3} padding={gutter}>
        <Composition gap={gutter}>
          <img src={image} alt="imagen referencial" />
        </Composition>
      </Composition>
    </Modal>
  );
};

export default ViewImageModal;
