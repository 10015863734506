/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { navigate as router } from '@reach/router';
import { Composition } from '@atomic-layout/emotion';
import { CSSInterpolation } from '@emotion/serialize';
import { FaBars } from 'react-icons/fa';

import Img from '../components/Img';
import { mq, theme } from '../constants';
import Logo from '../assets/logo.svg';
import Button from '../components/form/Button';
import SidePanel from '../components/SidePanel';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  //
}

const areasMobile = `
  menu
  main
`;

const areasTablet = `
  menu
  main
`;

const logoStyle: CSSInterpolation = {
  display: 'flex',
  height: '100%',
  paddingRight: '2rem',
  alignItems: 'center',
  span: {
    marginLeft: '1rem',
    fontWeight: 600,
    color: theme.colors.primary,
  },
  img: {
    cursor: 'pointer',
  },
};

const menu: { menu: string; url: string }[] = [
  { menu: '¿Cómo funciona?', url: 'https://boucompany.com/integrarse_a_bou/' },
  { menu: 'Quienes somos', url: 'https://boucompany.com/quienes_somos_bou/' },
  { menu: 'Blog', url: 'https://boucompany.com/blog/' },
];

const buttonContact = (navigate: any) => {
  return (
    <Button variant="primary" onClick={() => navigate('https://bit.ly/entregas_prueba')}>
      Habla con nosotros
    </Button>
  );
};

const styleButtonContact = mq({ display: ['none', 'none', 'block'] });
const styleMenuItems = mq({ display: ['none', 'flex', 'flex', 'flex'], gap: '1rem' });
const styleMenuButton = mq({ display: ['block', 'none'] });

const PersonLayout = (props: IProps) => {
  const { children, ...otherProps } = props;
  const [open, setOpen] = useState(false);

  const navigate = (url: string) => {
    window.open(url);
  };

  return (
    <Composition template={areasMobile} templateMd={areasTablet} css={{ height: '100vh', overflow: 'hidden' }}>
      {({ Menu, Main }) => (
        <>
          <Menu
            padding={10}
            as="nav"
            css={mq({
              display: 'flex',
              justifyContent: ['space-between', 'center'],
              alignItems: 'center',
              gap: '1.5rem',
              height: '60px',
            })}
          >
            <div css={{ ...logoStyle }}>
              <Img src={Logo} onClick={() => router('/')} />
              <span>Bou company</span>
            </div>
            <div css={styleMenuItems}>
              {menu.map(m => (
                <Button
                  simple
                  css={{ background: 'transparent', color: theme.colors.primary }}
                  onClick={() => navigate(m.url)}
                >
                  {m.menu}
                </Button>
              ))}
            </div>
            <div css={styleButtonContact}>{buttonContact(navigate)}</div>
            <div css={styleMenuButton}>
              <Button simple onClick={() => setOpen(true)}>
                <FaBars size={25} />
              </Button>
            </div>
          </Menu>
          <Main
            css={{ height: 'calc(100vh - 60px)', overflow: 'auto', position: 'relative' }}
            padding={20}
            {...otherProps}
            as="main"
          >
            <SidePanel isOpen={open} title="" onCloseClick={() => setOpen(false)}>
              <div css={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                {menu.map(m => (
                  <Button
                    simple
                    css={{ background: 'transparent', color: theme.colors.primary }}
                    onClick={() => navigate(m.url)}
                  >
                    {m.menu}
                  </Button>
                ))}
                {buttonContact(navigate)}
              </div>
            </SidePanel>
            {children}
          </Main>
        </>
      )}
    </Composition>
  );
};

export default PersonLayout;
