import { makeAutoObservable } from 'mobx';
import { QueryClient } from 'react-query';
import { IUser } from '../interfaces/IUser';

export default class UserStore {
  user: IUser | undefined;

  checkingLoginState = true;

  queryClient: QueryClient;

  constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
    makeAutoObservable(this);
  }

  /**
   * Save the user
   */
  setUser(user: IUser): void {
    this.user = user;
  }

  isCheckingLoginState(isChecking: boolean): void {
    this.checkingLoginState = isChecking;
  }
}
