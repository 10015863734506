/** @jsxImportSource @emotion/react */

import React from 'react';
import { CSSObject } from '@emotion/serialize';

interface IProps extends React.HTMLAttributes<HTMLHeadingElement> {
  css?: CSSObject;
  icon?: React.ReactNode;
}

const defaultStyles: CSSObject = {
  fontWeight: 'normal',
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  '.icon': {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
  },
};

export function H1(props: IProps) {
  const { children, css, icon } = props;

  const styles: CSSObject = {
    ...defaultStyles,
    fontSize: '1.5rem',
    ...css,
  };

  return (
    <h1 css={styles} {...props}>
      {icon && <span className="icon">{icon}</span>}
      {children}
    </h1>
  );
}

export function H2(props: IProps) {
  const { children, css, icon, color = 'black' } = props;

  const styles: CSSObject = {
    ...defaultStyles,
    fontSize: '1.3rem',
    ...css,
    color,
  };

  return (
    <h2 css={styles} {...props}>
      {icon && <span className="icon">{icon}</span>}
      {children}
    </h2>
  );
}

export function H3(props: IProps) {
  const { children, css, icon } = props;

  const styles: CSSObject = {
    ...defaultStyles,
    fontSize: '1.1rem',
    ...css,
  };

  return (
    <h3 css={styles} {...props}>
      {icon && <span className="icon">{icon}</span>}
      {children}
    </h3>
  );
}

export function H4(props: IProps) {
  const { children, css, icon } = props;

  const styles: CSSObject = {
    ...defaultStyles,
    fontSize: '0.9rem',
    ...css,
  };

  return (
    <h3 css={styles} {...props}>
      {icon && <span className="icon">{icon}</span>}
      {children}
    </h3>
  );
}
