import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAqYVr_vuykk4iDHKJgFFsIV63Rd00paLg',
  authDomain: 'boucompany-a954b.firebaseapp.com',
  databaseURL: 'https://boucompany-a954b.firebaseio.com',
  projectId: 'boucompany-a954b',
  storageBucket: 'boucompany-a954b.appspot.com',
  messagingSenderId: '790174790754',
  appId: '1:790174790754:web:39271f32e123815e',
};

firebase.initializeApp(config);

const db = firebase.firestore();
db.settings({});

export const firestore = db;

export default firebase;
