import { useMutation as reactQueryMutation, UseMutationOptions } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { UseFormMethods } from 'react-hook-form/dist/types';

export interface IAPIError<T> {
  error: AxiosError;
  message: string;
  validationErrors?: IUnprocessableEntityError<T>;
}

interface IUnprocessableEntityError<T> {
  errors: {
    [K in keyof T]?: string[];
  };
  message: string;
}

/**
 * Mutation wrapper that sets some general configs based on the API
 * @param request
 * @param options
 */
export const useMutation = <T, V = T>(
  request: (client: T) => Promise<AxiosResponse<V>>,
  options: UseMutationOptions<AxiosResponse<V>, IAPIError<T>, T, any>
) => {
  const { onError } = options;
  const parseError = (
    error: AxiosError,
    variables: T,
    context: any | undefined
    // eslint-disable-next-line consistent-return
  ): Promise<void> | void => {
    const parsedError: IAPIError<T> = {
      error,
      message: error.response?.data.message || error.message,
    };

    if (error.response?.status === 422) {
      parsedError.validationErrors = error.response?.data as IUnprocessableEntityError<T>;
    }

    if (onError) {
      return onError(parsedError, variables, context) as Promise<void>;
    }
  };

  return reactQueryMutation<AxiosResponse<V>, any, T>(newValues => request(newValues), {
    ...options,
    onError: parseError,
  });
};

/**
 * Sets the form's errors based on the object returned by the API
 * @param e
 * @param form
 */
export const setFormErrors = <T>(e: IAPIError<T>, form: UseFormMethods<T>) => {
  if (e.validationErrors) {
    Object.entries(e.validationErrors.errors).forEach(([prop, value]) => {
      let errors = '';
      // @ts-ignore
      // eslint-disable-next-line no-return-assign
      value.forEach(x => (errors += `${x}\n`));

      // @ts-ignore
      form.setError(prop, {
        type: 'manual',
        message: errors,
      });
    });
  }
};
