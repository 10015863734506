// /** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import { CSSInterpolation, CSSObject } from '@emotion/serialize';

interface IProps {
  css?: CSSObject;
  children: React.ReactNode[] | React.ReactNode;
}

const groupStyle: CSSInterpolation = {
  position: 'absolute',
  transform: 'translateY(.8rem)',
  transition: 'all .6s',
  opacity: 0,
  width: '100%',
  height: '100%',
  '&.show': {
    transform: 'translateY(0)',
    opacity: 1,
  },
};

const Slider = (props: IProps) => {
  const { children, css } = props;
  const items = React.Children.toArray(children);
  const [positionSlider, setPositionSlider] = useState<number>(0);

  const sliderStyle: CSSInterpolation = {
    position: 'relative',
    width: '250px',
    height: '250px',
    overflow: 'hidden',
    ...css,
  };

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index >= items.length) {
        setPositionSlider(0);
        index = 0;
      }
      setPositionSlider(index);
      index += 1;
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div css={sliderStyle} {...props}>
      {items.map((item, index) => (
        <div css={groupStyle} className={index === positionSlider ? 'show' : 'hidden'}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default Slider;
