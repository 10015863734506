/** @jsxImportSource @emotion/react */

import React, { HTMLAttributes } from 'react';
import { CSSInterpolation } from '@emotion/serialize';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  elevation?: 'none' | 'low' | 'normal' | 'high';
}

const Card = (props: IProps) => {
  // @ts-ignore
  const { children, css, elevation = 'normal' } = props;

  const elevations = {
    none: '0 0 0 1px rgb(0 0 0 / 20%)',
    low: '0 0 3px rgb(0 0 0 / 20%)',
    normal: '0 0 5px rgb(0 0 0 / 20%)',
    high: '0 0 20px rgb(0 0 0 / 15%)',
  };

  const bodyItemStyle: CSSInterpolation = {
    boxShadow: elevations[elevation],
    padding: '1rem',
    borderRadius: '.6rem',
    ...css,
  };

  return (
    <div {...props} css={bodyItemStyle}>
      {children}
    </div>
  );
};

export default Card;
