/** @jsxImportSource @emotion/react */
import { FaChevronLeft } from 'react-icons/fa';
import { UseFormMethods } from 'react-hook-form';
import { Composition } from '@atomic-layout/emotion';
import React, { Dispatch, SetStateAction, useState } from 'react';

import Img from '../../components/Img';
import Card from '../../components/Card';
import Order from '../../assets/Order.png';
import IReturn from '../../interfaces/IReturn';
import Animate from '../../components/Animate';
import { gutter, theme } from '../../constants';
import Button from '../../components/form/Button';
import Center from '../../components/elements/Center';
import { H2, H3, H4 } from '../../components/Headings';
import { Comment } from '../../interfaces/IOrder';
import ViewImageModal from '../../components/ViewImageModal';

interface IProps {
  setShowNoveltys: Dispatch<SetStateAction<boolean>>;
  form: UseFormMethods<IReturn>;
}

const NoveltysMain = (props: { noveltys: Comment[] }) => {
  const { noveltys } = props;
  return (
    <Composition gap={gutter}>
      <Animate>
        {noveltys?.length === 0 && <H2>No existen novedades</H2>}
        {noveltys
          ?.filter(n => n.type === 'NOVELTY')
          .map(novelty => (
            <Card>
              <H3>{novelty.comment}</H3>
              <H4>{novelty.date}</H4>
            </Card>
          ))}
      </Animate>
    </Composition>
  );
};

const ImagesMain = (props: { images: Comment[] | undefined }) => {
  const { images } = props;
  const [viewImageModal, setViewImageModal] = useState<{ open: boolean; image?: string }>({ open: false });

  const buildUrlImage = (comment: Comment) => {
    if (comment) {
      const cArray = comment.comment.split('/');
      const env = cArray[0];
      cArray.shift();
      return `https://${env}.s3.amazonaws.com/${cArray.join('/')}`;
    }
    return '';
  };

  const showImageModal = (comment: Comment) => {
    const image = buildUrlImage(comment);
    setViewImageModal({ open: true, image });
  };

  return (
    <Animate>
      {images?.length === 0 && <Center>No existen imágenes</Center>}

      {(images?.length ?? 0) > 0 && (
        <Composition gap={8} autoFlow="column">
          {images
            ?.filter(image => image.type === 'IMAGE')
            .map((img: any) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <img
                css={{ width: '100%' }}
                onClick={() => showImageModal(img)}
                src={buildUrlImage(img)}
                alt="Imagen de referencia"
              />
            ))}
        </Composition>
      )}
      <ViewImageModal
        onClose={() => setViewImageModal({ open: false, image: viewImageModal.image })}
        visible={viewImageModal.open}
        image={viewImageModal.image}
        size="md"
      />
    </Animate>
  );
};

const NoveltysAndImages = (props: IProps) => {
  const { setShowNoveltys, form } = props;
  const [step, setStep] = useState(1);
  const values = form.getValues();

  const onClick = (stepValue: number) => setStep(stepValue);

  return (
    <div css={{ width: '98%', maxWidth: '650px', gap: '15px' }}>
      <Animate>
        <FaChevronLeft onClick={() => setShowNoveltys(false)} size={30} color={theme.colors.primary} />
        <h2 css={{ textAlign: 'center' }}>Novedades e Imágenes</h2>
        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <Img src={Order} />
        </div>
        <Card>
          <Composition gap={gutter}>
            <Composition templateCols="1fr 1fr">
              <Button compact border={false} variant={step === 1 ? 'primary' : 'light'} onClick={() => onClick(1)}>
                Novedades
              </Button>
              <Button compact border={false} variant={step === 2 ? 'primary' : 'light'} onClick={() => onClick(2)}>
                Imagenes
              </Button>
            </Composition>
            {step === 1 && <NoveltysMain noveltys={values.order.comments} />}
            {step === 2 && <ImagesMain images={values.order.comments} />}
          </Composition>
        </Card>
      </Animate>
    </div>
  );
};

export default NoveltysAndImages;
