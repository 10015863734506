/** @jsxImportSource @emotion/react */

import { IconType } from 'react-icons/lib';
import React, { useRef, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Composition } from '@atomic-layout/emotion';
import { CSSInterpolation } from '@emotion/serialize';
import { FaCalendar, FaCheck, FaClipboardList, FaShippingFast } from 'react-icons/fa';

import { theme } from '../../../constants';
import Card from '../../../components/Card';
import IReturn from '../../../interfaces/IReturn';
import TimeLineVertical from './TimeLineVertical';
import { dateForHuman } from '../../../utils/utils';
import Button from '../../../components/form/Button';
import ItemTimeLineVertical from './ItemTimeLineVertical';

interface IProps {
  form: UseFormMethods<IReturn>;
}

const steps: { name: string; icon: IconType; index: number }[] = [
  { name: 'Pedido', icon: FaCalendar, index: 1 },
  { name: 'Enviado', icon: FaClipboardList, index: 2 },
  { name: 'En camino', icon: FaShippingFast, index: 3 },
  { name: 'Entregado', icon: FaCheck, index: 4 },
];

const colorsDisabled = '#E8E8E8';

const styleStepItem: CSSInterpolation = {
  display: 'flex',
  justifyContent: 'space-around',
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
  maxWidth: '650px',
  padding: '1rem .7rem',
  '.line': {
    height: '2px',
    width: 'calc(100% - 2rem)',
    background: colorsDisabled,
    position: 'absolute',
    transform: 'translateY(1.5rem)',
  },
  '.step-item': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&>div': {
      padding: '1.5rem',
      borderRadius: '50%',
      background: 'white',
      transition: 'all 1s linear',
      position: 'relative',
      border: `2px solid ${colorsDisabled}`,
      svg: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transition: 'all 1s',
        transform: 'translate(-50%, -50%)',
      },
    },
    span: {
      fontSize: '12px',
      fontWeight: 'bold',
      marginTop: '.5rem',
      transition: 'all 1s',
      color: colorsDisabled,
    },
  },
  '.selected': {
    '&>div': {
      borderColor: theme.colors.secondary,
    },
    span: {
      color: theme.colors.primary,
    },
  },
};

const TimeLine = (props: IProps) => {
  const { form } = props;
  const vertical = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  const values = form.getValues();

  let position = 1;

  switch (values.order.status) {
    case 'TOCOLLECT':
    case 'COLLECTED':
    case 'INWAREHOUSE':
      position = 2;
      break;
    case 'INTRANSIT':
    case 'INDELIVERYAREA':
      position = 3;
      break;
    case 'DELIVERED':
      position = 4;
      break;
    default:
  }

  return (
    <>
      <Card css={{ padding: '0', overflow: 'hidden' }}>
        <Composition>
          <div css={styleStepItem}>
            <div className="line" />
            {steps.map(step => (
              <div key={step.index.toString()} className={`step-item ${step.index <= position ? 'selected' : ''}`}>
                <div>
                  <step.icon size={25} color={step.index <= position ? theme.colors.primary : colorsDisabled} />
                </div>
                <span>{step.name}</span>
              </div>
            ))}
          </div>
        </Composition>
        <TimeLineVertical reference={vertical} expand={show}>
          <div ref={vertical} css={{ padding: '1.5rem 2rem', borderTop: '1px solid #efefef' }}>
            <Composition gap={30}>
              <ItemTimeLineVertical
                value={`Pedido el ${dateForHuman(values.order.order_information.date, {
                  weekday: 'long',
                })} ${dateForHuman(values.order.order_information.date, { day: 'numeric' })}`}
                active
              />
              <ItemTimeLineVertical active={position >= 2} value="Enviado" />
              <ItemTimeLineVertical active={position >= 3} value="En camino" />
              <ItemTimeLineVertical active={position >= 4} value="Entregado" isLast />
            </Composition>
          </div>
        </TimeLineVertical>
        <Button block variant="primary" border={false} compact onClick={() => setShow(!show)}>
          {!show ? 'VER MÁS' : 'VER MENOS'}
        </Button>
      </Card>
    </>
  );
};

export default TimeLine;
