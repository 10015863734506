/** @jsxImportSource @emotion/react */

import React, { memo } from 'react';
import { useTrail, animated } from 'react-spring';

interface IProps {
  children: React.ReactNode[] | React.ReactNode;
  reverse?: boolean;
  type?: 'fade' | 'slideUp' | 'slideDown';
}

/**
 * Animate children components
 * @param props
 * @constructor
 */
const Animate = (props: IProps) => {
  const { children, reverse = false, type = 'slideUp' } = props;
  const items = React.Children.toArray(children);

  const animations = {
    slideUp: {
      interpolation: (i: any) => `translate3d(0,${i}px,0)`,
      x: 50,
    },
    slideDown: {
      interpolation: (i: any) => `translate3d(0,${i}px,0)`,
      x: -50,
    },
    fade: {
      interpolation: () => 'none',
      x: 0,
    },
  };

  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: reverse ? 0 : 1,
    x: reverse ? animations[type].x : 0,
    from: { opacity: 0, x: animations[type].x },
  });

  return (
    <>
      {trail.map(({ x, ...rest }, index) => (
        <animated.div
          // @ts-ignore
          key={items[index].key}
          style={{
            ...rest,
            // @ts-ignore
            transform: x.interpolate(i => `translate3d(0,${i}px,0)`),
          }}
        >
          {items[index]}
        </animated.div>
      ))}
    </>
  );
};

export default memo(Animate);
