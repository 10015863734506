import axios from './axios_instance';
import { API_URL } from '../constants';
import { IOrder } from '../interfaces/IOrder';

const slug = 'orders';

export const getOrderReturn = (order: IOrder) => {
  return axios.get<IOrder>(`${API_URL}/tracking/orders`, { params: { uuid: order.uuid } });
};

export const updateOrder = (formData: FormData) =>
  axios.post(`${API_URL}/${slug}-update/${formData.get('uuid')}`, formData);
