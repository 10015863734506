import React from 'react';
import Modal from 'react-modal';
import { Router } from '@reach/router';
import Layout from '@atomic-layout/emotion';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { css, ThemeProvider, Global } from '@emotion/react';

import { store, StoreContext } from './stores';
import { breakpoints, shadows, theme } from './constants';
import './styles/index.scss';
import GoogleAnalytics from './components/GoogleAnalytics';
import Landing from './pages/landing/Landing';
import Tracking from './pages/tracking/Tracking';

const globalStyles = css({
  // Normalizations and resets
  'html, body': {
    padding: 0,
    margin: 0,
    fontFamily: 'Montserrat, sans-serif',
    color: '#5F5F5F',
    height: '100%',
  },
  '#root': {
    height: '100%',
  },
  /* width */
  '::-webkit-scrollbar': {
    width: '5px',
  },

  /* Track */
  '::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: '#d1dd0059',
  },
  /* Handle on hover */
  '::-webkit-scrollbar-thumb:hover': {
    background: theme.colors.secondary,
  },
  a: {
    color: 'inherit',
    cursor: 'pointer',
    '&:hover, &:focus': {
      color: theme.colors.accent,
    },
    textDecoration: 'none',
  },
  '*': {
    boxSizing: 'border-box',
  },
  p: {
    marginTop: 0,
    marginBottom: '1rem',
    ':last-child': {
      marginBottom: 0,
    },
  },
  'input[type="checkbox" i]': {
    margin: '0 0.5rem 0 0',
  },

  // Utilities
  '.relative': { position: 'relative' },
  '.absolute': { position: 'absolute' },
  '.fixed': { position: 'fixed' },
  '.static': { position: 'static' },
  '.flex': { display: 'flex' },
  '.block': { display: 'block' },
  '.flex-col, .flex-column': { flexDirection: 'column' },
  '.justify-center': { justifyContent: 'center' },
  '.justify-between': { justifyContent: 'space-between' },
  '.items-center': { alignItems: 'center' },
  '.items-end': { alignItems: 'flex-end' },
  '.text-white': { color: 'white' },
  '.text-center': { textAlign: 'center' },
  '.flex-grow': { flexGrow: 1 },
  '.w-full': { width: '100%' },
  '.h-full': { height: '100%' },
  '.shadow-lg': { boxShadow: shadows.large },
  '.bg-white': { background: 'white' },
  '.overflow-hidden': { overflow: 'hidden' },
  '.uppercase': { textTransform: 'uppercase' },
  '.p1': { padding: '1rem' },
  '.p-05': { padding: '0.5rem' },
  '.m0': { margin: 0 },
  hr: {
    borderTop: '1px solid #0000004f',
    width: '100%',
    height: 0,
  },
  '.ellipsis': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

Modal.setAppElement('#root');

Layout.configure({
  defaultBreakpointName: 'sm',
  breakpoints: {
    sm: {
      maxWidth: breakpoints[0],
    },
    md: {
      minWidth: breakpoints[0] + 1,
      maxWidth: breakpoints[1],
    },
    lg: {
      minWidth: breakpoints[1] + 1,
      maxWidth: breakpoints[2],
    },
  },
});

// Router.events.on('routeChangeComplete', url => pageView(url));

function App() {
  return (
    <QueryClientProvider client={store.queryClient}>
      <StoreContext.Provider value={store}>
        <Global styles={globalStyles} />
        <ThemeProvider theme={theme}>
          <Router>
            <GoogleAnalytics default>
              <Landing path="/" />
              <Tracking path="/tracking" />
              <Tracking path="/tracking/:id" />
            </GoogleAnalytics>
          </Router>
          <ToastContainer />
        </ThemeProvider>
      </StoreContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
