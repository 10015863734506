/** @jsxImportSource @emotion/react */

import React from 'react';
import { CSSInterpolation } from '@emotion/serialize';

/**
 * Input error message
 * @param props
 * @constructor
 */
const InputError = (props: { message: string }) => {
  const { message } = props;

  const styles: CSSInterpolation = {
    padding: '0.3rem',
    background: 'rgb(213 17 17 / 68%)',
    color: 'white',
    fontSize: '0.7rem',
  };

  return <div css={styles}>{message}</div>;
};

export default InputError;
