/** @jsxImportSource @emotion/react */

import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { CSSInterpolation } from '@emotion/serialize';

import { theme } from '../../../constants';
import { H3 } from '../../../components/Headings';

interface IProps {
  active: boolean;
  value: string;
  isLast?: boolean;
}

const buildStyles = (isLast: boolean): CSSInterpolation => {
  const after: CSSInterpolation = !isLast
    ? {
        '&:not(:last-child)::after': {
          content: '""',
          position: 'absolute',
          height: '25px',
          borderRight: '3px dashed #EFEFEF',
          bottom: '-24px',
        },
      }
    : { '&:not(:last-child)::after': {} };

  return {
    display: 'flex',
    position: 'relative',
    gap: '20px',
    '.icon': {
      width: '25px',
      height: '25px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#EFEFEF;',
      borderRadius: '50%',
      position: 'relative',
      ...after,
    },
    '.icon.active': {
      background: theme.colors.secondary,
    },
  };
};

const ItemTimeLineVertical = (props: IProps) => {
  const { active, isLast = false, value } = props;

  return (
    <div css={buildStyles(isLast)}>
      <div className={`icon ${active ? 'active' : ''}`}>
        <FaCheck color="white" />
      </div>
      <H3>{value}</H3>
    </div>
  );
};

export default ItemTimeLineVertical;
