/** @jsxImportSource @emotion/react */

import React from 'react';
import { CSSObject } from '@emotion/serialize';

/**
 * Label animation to be used with text and select inputs
 * @param props
 * @constructor
 */
const Label = (props: { children: React.ReactNode; label: string; name: string; compact?: boolean }) => {
  const { children, label, name, compact = false } = props;
  const groupStyles: CSSObject = {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    'input:not([readonly]:focus~label), .has-value~label': {
      bottom: compact ? '2.4rem' : '3.5rem',
      fontSize: compact ? '0.7rem' : '0.9rem',
    },
  };

  const labelStyles: CSSObject = {
    bottom: compact ? '1.6rem' : '2.1rem',
    margin: `0 2rem 0 ${compact ? 0.5 : 1}rem`,
    position: 'relative',
    height: 0,
    backgroundColor: 'white',
    fontSize: '0.9rem',
    transition: '0.2s',
    pointerEvents: 'none',
    alignSelf: 'flex-start',
  };

  const textStyles: CSSObject = {
    background: 'white',
    padding: '0px 5px',
    marginLeft: compact ? 'calc(5px - 0.5rem)' : -5,
  };

  return (
    <div css={groupStyles}>
      {children}
      <label htmlFor={name} css={labelStyles}>
        <span css={textStyles}>{label}</span>
      </label>
    </div>
  );
};

export default Label;
