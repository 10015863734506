/** @jsxImportSource @emotion/react */

import React, { forwardRef, InputHTMLAttributes, useEffect, useState } from 'react';
import { CSSObject } from '@emotion/serialize';
import { useFormContext } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import Label from '../Label';
import InputError from './InputError';
import { theme } from '../../constants';

export const inputStyles: CSSObject = {
  border: 1,
  height: '3rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  borderStyle: 'solid',
  borderColor: theme.colors.inputBorder,
  width: '100%',
  background: 'transparent',
  textOverflow: 'ellipsis',
};

interface IProps extends InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement & HTMLSelectElement> {
  label?: string;
  name: string;
  field?: 'input' | 'textarea';
  innerIcon?: React.ReactNode;
  prepend?: React.ReactNode | string;
  append?: React.ReactNode | string;
}

const parseError = (name: string, errors: FieldErrors): string | undefined => {
  if (!errors) {
    return undefined;
  }

  const nameArr = name.split('.');
  let value = errors;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= nameArr.length - 1; i++) {
    if (nameArr[i] && value[nameArr[i]]) {
      value = value[nameArr[i]];
    }
  }

  return value.message;
};

const InputField = forwardRef((props: IProps, ref: any) => {
  const { label, innerIcon, field, prepend, append, ...otherProps } = props;
  const { defaultValue, name, type } = props;
  const [error, setError] = useState<string>();
  const form = useFormContext();
  const value = form?.watch(name);

  const groupStyles: CSSObject = {
    opacity: props.readOnly ? 0.7 : 1,
    display: 'flex',
    width: '100%',
    '.prepend, .append': {
      background: '#e8e8e8',
      display: 'flex',
      alignItems: 'center',
      padding: '0 0.5rem',
      fontSize: '0.8rem',
    },
  };

  useEffect(() => {
    setError(parseError(name, form?.formState.errors));
  }, [form?.formState]);

  const renderInput = () => {
    if (field === 'textarea') {
      // TODO add style
      return <textarea {...props} ref={ref || form?.register} cols={30} rows={10} />;
    }

    if (type === 'checkbox') {
      // TODO add style
      return (
        <label htmlFor={otherProps.id}>
          <input {...otherProps} ref={ref || form?.register} />
          {label}
        </label>
      );
    }

    return (
      <>
        <input
          {...otherProps}
          ref={ref || form?.register}
          className={defaultValue || value ? 'has-value' : ''}
          css={{
            ...inputStyles,
            paddingRight: innerIcon ? '2.5rem' : '1rem',
          }}
        />
        {innerIcon && (
          <div
            css={{
              display: 'flex',
              position: 'absolute',
              right: '1.2rem',
              top: '50%',
              transform: 'translateY(calc(-50%))',
            }}
          >
            {innerIcon}
          </div>
        )}
      </>
    );
  };

  if (type === 'checkbox' || type === 'hidden') {
    return renderInput();
  }

  if (!label) {
    return (
      <>
        <div css={groupStyles}>
          {prepend && <span className="prepend">{prepend}</span>}
          {renderInput()}
          {append && <span className="append">{append}</span>}
        </div>
        {error && <InputError message={error} />}
      </>
    );
  }

  return (
    <>
      <div css={groupStyles}>
        {prepend && <span className="prepend">{prepend}</span>}
        <Label label={label || ''} name={name || ''}>
          {renderInput()}
        </Label>
        {append && <span className="append">{append}</span>}
      </div>
      {error && <InputError message={error} />}
    </>
  );
});

export default InputField;
