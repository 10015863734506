import { useLocation } from '@reach/router';
import React, { HTMLAttributes, useEffect } from 'react';
import { pageView } from '../utils/gtag';

interface IProps extends HTMLAttributes<HTMLElement> {
  // eslint-disable-next-line react/no-unused-prop-types
  default: boolean; // This is only used so that Reach Router doesn't complain
}

const GoogleAnalytics = (props: IProps) => {
  const { children } = props;
  const location = useLocation();

  useEffect(() => {
    pageView(location.pathname);
  }, [location]);

  return <>{children}</>;
};

export default GoogleAnalytics;
