/** @jsxImportSource @emotion/react */

import React from 'react';
import { AxiosResponse } from 'axios';
import { UseMutationResult } from 'react-query';
import { UseFormMethods } from 'react-hook-form';
import { Composition } from '@atomic-layout/emotion';

import Card from '../../components/Card';
import { gutter, mq } from '../../constants';
import IReturn from '../../interfaces/IReturn';
import { IOrder } from '../../interfaces/IOrder';
import Button from '../../components/form/Button';
import InputField from '../../components/form/InputField';

interface IProps {
  form: UseFormMethods<IReturn>;
  mutation: UseMutationResult<AxiosResponse<IOrder>, any, IOrder>;
}

const FindOrder = (props: IProps) => {
  const { form, mutation } = props;

  return (
    <Card css={{ width: '98%', maxWidth: '600px' }}>
      <div css={mq({ padding: ['.2rem', '3rem'] })}>
        <Composition gap={gutter}>
          <InputField name="order_uuid" label="Ingresa tu número de tracking" ref={form.register()} />
          <Button
            variant="primary"
            onClick={() => {
              mutation.mutate({ uuid: form.getValues().order_uuid } as IOrder);
            }}
          >
            Buscar
          </Button>
        </Composition>
      </div>
    </Card>
  );
};

export default FindOrder;
