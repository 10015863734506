/** @jsxImportSource @emotion/react */

import React from 'react';
import { useTheme } from '@emotion/react';
import { mq } from '../../constants';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'light' | 'dark' | 'primary' | 'finalize' | 'danger';
  size?: 'large' | 'medium' | 'base' | 'small';
  simple?: boolean;
  block?: boolean;
  compact?: boolean;
  border?: boolean;
}

const generateStyles = (
  size: IProps['size'] = 'base',
  variant: IProps['variant'],
  simple?: boolean,
  block?: boolean,
  compact?: boolean,
  isIconButton?: boolean,
  border?: boolean
) => {
  const mainTheme = useTheme();
  const sizes = {
    large: {
      fontSize: '1.5rem',
      padding: ['0.7rem 2rem', '1rem 2rem'],
    },
    medium: {
      fontSize: '1.1rem',
      padding: ['1rem 2rem', '1rem 2rem'],
    },
    base: {
      fontSize: '1rem',
      padding: ['0.7rem 1rem', '0.7rem 3rem'],
    },
    small: {
      fontSize: '0.9rem',
      padding: ['0 0.8rem', '0.7rem 3rem'],
    },
  };

  let background = '#fff';

  if (variant === 'primary') {
    background = mainTheme.colors.primary;
  }

  if (variant === 'finalize') {
    background = mainTheme.colors.accent;
  }

  if (variant === 'danger') {
    background = 'red';
  }

  let { padding } = sizes[size];

  // "Simple" buttons dont have any padding, the rest do
  if (simple) {
    padding = ['0'];
  }

  if (compact) {
    padding = ['0 0.5rem'];
  }

  if (isIconButton) {
    padding = ['0.5rem'];
  }

  return mq({
    transition: 'all 150ms ease-in',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    // "Simple" buttons have no border
    border: simple || !border ? 'none' : '1px solid',
    // Block buttons are 100% width
    width: block ? '100%' : 'auto',
    borderRadius: compact ? 3 : 500,
    textTransform: 'uppercase',
    padding,
    // "Dark" and "primary" buttons are white
    color: variant === 'light' ? '#7c7c7c' : '#fff',
    fontSize: sizes[size].fontSize,
    height: compact ? '2rem' : 'auto',
    background,
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover,&:focus': {
      // "Primary" buttons should be white on hover
      // or else we can't see the text
      color: variant === 'primary' ? '#fff' : mainTheme.colors.primary,
      background,
    },
    '&[disabled]': {
      opacity: 0.5,
    },
  });
};

/**
 * Button component used for UI actions
 * @param props
 * @constructor
 */
const Button = (props: IProps) => {
  const { children, variant = 'light', size, simple, block, compact = false, border = true, ...otherProps } = props;

  const styles = generateStyles(size, variant, simple, block, compact, false, border);

  return (
    <button type="button" css={styles} {...otherProps}>
      {children}
    </button>
  );
};

/**
 * Icon only button component used for UI actions
 * @param props
 * @constructor
 */
export const IconButton = (props: IProps) => {
  const { children, variant = 'light', size, simple, block, compact = false, ...otherProps } = props;

  const styles = generateStyles(size, variant, simple, block, compact, true);

  return (
    <button type="button" css={styles} {...otherProps}>
      {children}
    </button>
  );
};

export default Button;
