import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Composition } from '@atomic-layout/emotion';
import { navigate, RouteComponentProps } from '@reach/router';

import FindOrder from './FindOrder';
import FormTracking from './FormTracking';
import Form from '../../components/form/Form';
import IReturn from '../../interfaces/IReturn';
import Loading from '../../components/Loading';
import { IOrder } from '../../interfaces/IOrder';
import { H1, H2 } from '../../components/Headings';
import Center from '../../components/elements/Center';
import PersonLayout from '../../layouts/PersonLayout';
import { useMutation } from '../../utils/request.utils';
import { getOrderReturn } from '../../services/orders.service';
import { gutter } from '../../constants';

interface IProps extends RouteComponentProps {
  id?: string;
}

const Tracking = (props: IProps) => {
  const { id } = props;
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const form = useForm<IReturn>({
    // resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const closeFunction = () => {
    form.setValue('order', null);
    form.setValue('order_uuid', null);
    if (id) {
      navigate('/tracking');
    }
  };

  const mutation = useMutation<IOrder>(getOrderReturn, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async response => {
      form.setValue('order', response.data);
      setLoading(false);
    },
    onError: (e: any) => {
      if (e.error.response.status === 404 && !id) {
        toast.error(`No se pudo encontrar la orden ${form.getValues().order_uuid}`);
      } else if (e.error.response.status === 404 && id) {
        setNotFound(true);
      } else {
        toast.error(e.message);
      }
      setLoading(false);
    },
  });

  const values = form.watch();

  useEffect(() => {
    if (id) {
      setLoading(true);
      mutation.mutate({ uuid: id } as IOrder);
    }
  }, []);

  return (
    <PersonLayout>
      {!notFound && (
        <Form css={{ height: '100%' }} form={form}>
          {!values.order && !id && (
            <Center>
              <FindOrder mutation={mutation} form={form} />
            </Center>
          )}
          {values.order && <FormTracking close={closeFunction} form={form} />}
        </Form>
      )}
      {loading && <Loading type="container" />}
      {notFound && (
        <Center>
          <Composition gap={gutter} placeItems="center">
            <H1>No se ha encontrado la orden</H1>
            <H2>{id}</H2>
          </Composition>
        </Center>
      )}
    </PersonLayout>
  );
};

export default Tracking;
