import { createContext } from 'react';
import { QueryClient } from 'react-query';
import UserStore from './user-store';

export default class RootStore {
  userStore: UserStore;

  // Client used for queries
  queryClient: QueryClient;

  constructor() {
    this.queryClient = new QueryClient();
    this.userStore = new UserStore(this.queryClient);
  }
}

export const store = new RootStore();
export const StoreContext = createContext<RootStore>(store);
