import axios from 'axios';
import firebase from './firebase.service';

const instance = axios.create();

instance.interceptors.request.use(
  async config => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  config => config,
  error => {
    if (error.response.status === 401) {
      // TODO refresh token here
      if (firebase.auth().currentUser) {
        return firebase
          .auth()
          .currentUser?.getIdToken()
          .then(token => {
            localStorage.setItem('token', token);
            return instance.request(error.config);
          });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
