import React, { HTMLAttributes } from 'react';
import { useTrail, animated as a } from 'react-spring';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  reference: React.RefObject<HTMLDivElement>;
  expand: boolean;
}

const config = { mass: 5, tension: 2000, friction: 200 };

const TimeLineVertical = (props: IProps) => {
  const { children, reference, expand } = props;

  const trail = useTrail(1, { config, height: expand ? reference?.current?.clientHeight ?? 0 : 0 });

  return (
    <>
      {trail.map(({ height }) => (
        <a.div key={`vert-${height}`} style={{ position: 'relative', overflowY: 'hidden', height }}>
          {children}
        </a.div>
      ))}
    </>
  );
};

export default TimeLineVertical;
