/** @jsxImportSource @emotion/react */

import React from 'react';
import { Composition } from '@atomic-layout/emotion';
import { navigate, RouteComponentProps } from '@reach/router';

import Img from '../../components/Img';
import logo from '../../assets/logo.svg';
import { gutter } from '../../constants';
import Slider from '../../components/Slider';
import Button from '../../components/form/Button';
import Center from '../../components/elements/Center';
import PersonLayout from '../../layouts/PersonLayout';
import Uno from '../../assets/1.jpg';
import Dos from '../../assets/2.jpg';
import Tres from '../../assets/3.jpg';

interface IProps extends RouteComponentProps {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Landing = (props: IProps) => {
  return (
    <>
      <PersonLayout>
        <Composition justifyContent="center" placeItems="center" gap={gutter}>
          <Img src={logo} />
          <Slider css={{ width: '100vw', maxWidth: '650px', height: '400px' }}>
            <div css={{ width: '100%', height: '100%' }}>
              <Center>
                <Img src={Uno} />
              </Center>
            </div>
            <div css={{ width: '100%', height: '100%' }}>
              <Center>
                <Img src={Dos} />
              </Center>
            </div>
            <div css={{ width: '100%', height: '100%' }}>
              <Center>
                <Img src={Tres} />
              </Center>
            </div>
          </Slider>
          {/* <Button
            block
            onClick={() => {
              navigate('/return');
            }}
          >
            Devolución
          </Button> */}
          <Button block onClick={() => navigate('/tracking')}>
            Tracking
          </Button>
        </Composition>
      </PersonLayout>
    </>
  );
};

export default Landing;
